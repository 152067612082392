import Vue from 'vue'
import checkView from 'vue-check-view'
import VueResource from 'vue-resource'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';
import router from 'information/vue-config/router'
import store from 'information/store/index'
import types from 'information/store/types'
import 'shared/config/mixins'
import 'shared/config/filters'
import 'shared/vendors/moment-fr'

import InformationApp from 'information/components/InformationApp'
import Loader from 'shared/components/utils/Loader'
import BuilderLoop from 'shared/components/builder/BuilderLoop'
import VueFooter from 'shared/components/atoms/Footer'
import VueHeader from 'information/components/headers/VueHeader'
import 'information/assets/scss/app.scss'

//import vendors
import gsap from 'shared/vendors/gsap'
import Plyr from 'shared/vendors/Plyr'

//global components
import UnderlineGreenBrush from 'shared/components/utils/UnderlineGreenBrush'

Vue.use(checkView)
Vue.use(VueResource)

// Meta descriptions
Vue.use(VueMeta, {})

// Analytics
const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueAnalytics, {
	id: process.env.VUE_APP_APP_GOOGLE_ANALYTICS_ID,
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd,
    },
});

Vue.config.productionTip = false
Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

//mount the app on the specific div#eclo-app
const informationApp = new Vue({
	el: '#eclo-app',
	router: router,
	store: store,
	components:{
		InformationApp,
		Loader,
		UnderlineGreenBrush,
		BuilderLoop,
		VueFooter,
		VueHeader
	},
	created() {
		//Call function that is needed for the app
		if (window.__DONT_LOAD_DATA__) {
			return;
		}

		this.$store.dispatch(types.FETCH_INITIAL_DATA)
	},
	mounted() {
		let body = document.querySelector('body')
		body.className = body.className.replace('no-js', '') + ' js ready'
	}
})


//exposed Vue
window.Vue = Vue

export default informationApp
