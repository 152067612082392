import Vue from 'vue'
import Router from 'vue-router'
import vueRoutes from 'information/config/vue-routes'
import {addClass, removeClass} from 'shared/utils/utils'

Vue.use(Router)

//handle scroll with our code, not the default browser setup or vue.js
if (typeof history.scrollRestoration !== 'undefined') {
	history.scrollRestoration = 'manual'
}

let router = new Router({
	base: '/',//make everything under /dashboard
	routes: vueRoutes,
	mode: 'history',  //remove # in the url bar,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
				// , offset: { x: 0, y: 10 }
			}
		} else if (savedPosition) {
			return savedPosition
		} else {
			return {x: 0, y: 0}
		}
	}
})

router.afterEach((to, from, next) => {

	removeClass(document.body, `page-${from.name}`)
	addClass(document.body, `page-${to.name}`)


	if (typeof window.gtag !== 'undefined') {
		window.gtag('event', 'page_view', {'page_path': window.location.pathname})
	}

})

window.vueRouter = router

export default router
