import types from 'information/store/types'
import {routes, dynamicRoutes} from 'information/config/routes'
import ajax from 'shared/utils/ajax'
import apiActions from 'information/config/apiActions'
import {isEmpty} from 'shared/utils/utils'


// initial state, with craft backend or empty
let initData = JSON.parse(JSON.stringify(window.__initialData__.data || {}))
const state = {
    regions: initData ? initData.regions : [],
    informations: initData ? initData.informations : [],
    strategies: initData ? initData.strategies : [],
    steps: initData ? initData.steps : [],
    ages: initData ? initData.ages : [],
    globals: initData ? initData.globals : {},
}

// getters, make function easy to access by vue
//instead
const getters = {
    getInformationByOrderIdx: state => idx => {
        idx = parseInt(idx, 10)
        return state.informations.find(i => i.idx + 1 === idx) || null
    },
}

// actions
const actions = {
    [types.FETCH_INITIAL_DATA](store) {
        store.dispatch(types.GET_DATA)
            .then(() => {
                store.commit(types.FETCH_INITIAL_DATA, true)
                store.commit(types.STOP_INITIAL_LOADER)
            })

    },
    [types.GET_DATA](store) {
        if (!isEmpty(store.state.regions)) {
            return Promise.resolve()
        }
        return new Promise((resolve, reject) => {
            ajax({
                url: apiActions.data.getAll,
                method: 'get'
            })
                .then(data => {
                    store.commit(types.GET_DATA, data.data)
                    resolve(data.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}

// mutations
const mutations = {
    [types.GET_DATA](state, data) {
        //set to new data or dont change it
        state.regions = data.regions ? data.regions : state.regions
        state.steps = data.steps ? data.steps : state.steps
        state.ages = data.ages ? data.ages : state.ages
        state.informations = data.informations ? data.informations : state.informations
        state.globals = data.globals ? data.globals : state.globals
        state.strategies = data.strategies ? data.strategies : state.strategies
    },
    [types.FETCH_INITIAL_DATA](state, data) {

    }
}

export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations
}
