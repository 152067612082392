<template>
	<div class="information container-fluid" v-if="information">
		<div class="row center-xs middle-xs my-large">
			<div class="col-xs-12 col-sm-10 col-md-10 col-lg-8">
				<h5 class="text-align-left">{{ information.resume }}</h5>
				<div class="text-align-left mt-x-small" v-html="information.question"></div>
				<h4 class="mt-medium text-align-left col-xs-12 col-sm-10 col-md-10 no-gutter"
					v-if="information.context">
					Contexte: {{ information.context }}
				</h4>
				<img :src="information.imageURL" class="information__img mt-large"/>

				<h5 class="mt-medium mb-small text-align-left">Étapes</h5>
				<div class="information__answers">
					<div class="answer" v-for="(answer, answerIdx) in information.answers" :key="answer.id">
						<dropdown>
							<template v-slot:header>
								<div class="answer__header">
									<round-btn size="normal" color="yellow">
										{{ answerIdx + 1 }}
									</round-btn>
									<p class="ml-small text-align-left" v-html="answer.text"></p>
								</div>
							</template>
							<div class="answer__content">
								<information-step :next-answer="nextAnswer(answerIdx)"
												  :answer="answer"></information-step>
							</div>
						</dropdown>
					</div>
				</div>
			</div>
		</div>

		<div class="row center-xs middle-xs my-large">
			<router-link v-if="orderIdx !== 1" class="btn btn-primary btn-default-brush"
						 :class="{'mr-small':!isLastInformation }"
						 :to="`/informations/${orderIdx - 1}`">
				<brush-btn type="btn-large">
					<sprite id="arrow-left"></sprite>
					Précédent
				</brush-btn>
			</router-link>
			<router-link v-if="!isLastInformation" class="btn btn-primary btn-default-brush"
						 :class="{'ml-small':orderIdx !== 1 }"
						 :to="`/informations/${orderIdx + 1}`">
				<brush-btn type="btn-large">
					Suivant
					<sprite id="arrow-right"></sprite>
				</brush-btn>
			</router-link>
		</div>
	</div>
</template>

<script>
import Dropdown from "information/components/atoms/Dropdown";
import RoundBtn from "shared/components/atoms/btns/RoundBtn";
import InformationStep from "information/components/atoms/InformationStep";
import BrushBtn from "shared/components/atoms/btns/BrushBtn";
import Sprite from "shared/components/utils/Sprite";

export default {
	name: 'information',
	metaInfo() {
		return {
			title: `${this.information.resume}`
		}
	},
	components: {Sprite, BrushBtn, InformationStep, RoundBtn, Dropdown},
	created() {
		if (isNaN(this.$route.params.orderIdx)) {
			this.$router.push('/')
		}

		this.$nextTick(() => {
			if (!this.information) {
				this.$router.push('/')
			}
		})

	},
	computed: {
		orderIdx() {
			return parseInt(this.$route.params.orderIdx)
		},
		isLastInformation() {
			return this.$store.getters.getInformationByOrderIdx(this.orderIdx + 1) === null
		},
		information() {
			return this.$store.getters.getInformationByOrderIdx(this.orderIdx) || null
		}
	},
	methods: {
		nextAnswer(answerIdx) {
			if (!this.information) return null
			if (typeof this.information.answers[answerIdx + 1] === 'undefined') {
				return null
			}
			return this.information.answers[answerIdx + 1]
		}
	}
}
</script>
