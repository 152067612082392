const apiActions = {
    result: {
        create: '/actions/eclo-app-module/result/create-guest',
    },
    data: {
        getAll: '/actions/eclo-app-module/data/get-all-informations',
    },
}

export default apiActions
