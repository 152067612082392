<template>

  <div class="create-result container-fluid">

    <div class="row center-xs middle-xs mb-medium">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
        <img src="~shared/assets/images/kids/start-kid.png" class="block create-result__img h-auto"/>
        <h2 class="py-small text-align-left">
          Avant de commencer, svp veuillez remplir ce&nbsp;court formulaire à des&nbsp;fins statistiques.
        </h2>

        <loading-container loaderName="create-result">
          <form @submit.prevent="createResult" class="create-children">

            <div class="input-container my-medium">
              <h5>
                Parent ou intervenant
              </h5>
              <div class="flex flex-row mt-x-small mb-small"
                   v-if="$store.state.data.globals.publicUserGroups"
              >
                <div
                    v-for="userGroup in $store.state.data.globals.publicUserGroups"
                    :key="userGroup.handle"
                >
                  <button
                      type="button"
                      @click="selectUserGroup(userGroup.handle)"
                      class="btn btn-secondary user-group-btn"
                      :class="{'active': userGroupHandle === userGroup.handle }">{{ userGroup.name }}
                  </button>
                </div>
              </div>

              <p class="error">{{ validationErrors.userGroup }}</p>
            </div>

            <div class="input-container my-medium">
              <label>Région</label>
              <div class="select-container">
                <select v-model="region" class="reverse">
                  <option value="-">Votre région</option>
                  <option
                      v-for="region in $store.state.data.regions"
                      :key="region.id"
                      :value="region.id"
                  >{{ region.title }}
                  </option>
                </select>
              </div>
              <p class="error">{{ validationErrors.region }}</p>
            </div>

            <div class="create-result__newsletter">
              <div class="flex flex-row center-xs middle-xs">
                <toggle :checked="sendStrategy" v-on:change="toggleSendStrategy"></toggle>
                <span class="strategy-text">Je souhaites recevoir l'infolettre</span>
              </div>

              <div class="input-container mt-medium mb-0" v-if="sendStrategy">
                <label>Courriel</label>
                <input
                    class="reverse"
                    placeholder="Courriel"
                    v-model="email"
                    :id="'email-' + _uid"
                    type="text"
                    name="email"
                >
                <p class="error">{{ validationErrors.email }}</p>
              </div>
            </div>


            <div class="row center-xs middle-xs mt-medium">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                <button
                    @click.prevent="createResult"
                    class="btn btn-default-brush transform--50 mt-medium"
                    :class="{'disabled': !isComplete}">

                  <brush-btn type="btn-xxlarge">
                    Accéder&nbsp;à&nbsp;l’information
                    <sprite id="arrow-right"></sprite>
                  </brush-btn>
                </button>
              </div>
            </div>
          </form>
        </loading-container>
      </div>
    </div>
  </div>


</template>

<script>
import Vue from 'vue'
import {isEmpty} from 'shared/utils/utils'
import types from 'information/store/types'
import {dynamicRoutes} from 'information/config/routes'
import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
import Sprite from 'shared/components/utils/Sprite'
import InlineSvg from 'shared/components/utils/InlineSvg'
import LoadingContainer from 'shared/components/container/LoadingContainer'
import UnderlineLink from 'shared/components/atoms/UnderlineLink'
import BgBrush from 'shared/components/atoms/BgBrush'
import Toggle from 'shared/components/atoms/Toggle'
import ajax from "shared/utils/ajax";
import apiActions from "information/config/apiActions";
import {isEmail} from "shared/utils/validation";

const validateForm = (data) => {
  let errors = {}

  if (isEmpty(data.regionId)) {
    errors['region'] = 'Veuillez choisir une région'
  }

  if (isEmpty(data.userGroup)) {
    errors['userGroup'] = 'Veuillez choisir un type d\'utilisateur'
  }

  if (data.sendStrategy && isEmpty(data.email)) {
    errors['email'] = 'Le courriel ne peut pas être vide'
  }

  if (data.sendStrategy && !isEmpty(data.email) && !isEmail(data.email)) {
    errors['email'] = 'Le courriel n\'est pas valide'
  }

  return errors
}


export default {
  name: 'create-result',
  components: {
    BgBrush,
    UnderlineLink,
    Toggle, LoadingContainer, InlineSvg, Sprite, BrushBtn
  },
  data: function () {
    return {
      userGroupHandle: 'parent',
      region: '-',
      sendStrategy: false,
      email: '',

      validationErrors: {}
    }
  },
  metaInfo() {
    return {
      title: 'Accéder à l’information'
    }
  },
  computed: {
    isComplete: function () {
      return !isEmpty(this.region) && this.region !== '-' && !isEmpty(this.userGroupHandle)
    }
  },
  methods: {
    selectUserGroup: function (userGroupHandle) {
      this.userGroupHandle = userGroupHandle
    },
    toggleSendStrategy: function (newValue) {
      if (!newValue) {
        this.email = ''//reset
      }
      this.sendStrategy = newValue
    },
    async createResult() {
      //not complete yet
      if (!this.isComplete) return


      let data = {
        regionId: this.region,
        userGroup: this.userGroupHandle,
        email: this.email,
        sendStrategy: this.sendStrategy
      }
      let validationErrors = validateForm(data)

      //reset errors
      this.validationErrors = validationErrors

      //Check for validation errors before
      if (!isEmpty(validationErrors)) {
        return// don't make the ajax call
      }

      this.$store.dispatch(types.START_LOADER, 'create-result')


      try {
        let response = await ajax({
          url: apiActions.result.create,
          method: 'post',
          data: data
        })

        Vue.http.headers.common['x-author-id'] = response.authorId
        //todo: localstorage ?

        this.$router.push(dynamicRoutes.information(1))
        this.$store.dispatch(types.STOP_LOADER, 'create-result')
      } catch (e) {
        console.error(e)
        this.$store.dispatch(types.STOP_LOADER, 'create-result')
      }


    }
  }
}

</script>
