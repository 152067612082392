<template>
  <div key="resultQuestion" class="menu questions-header">
    <router-link
        class="link-with-brush menu-item"
        v-for="(info, index) in $store.state.data.informations"
        :key="info.id"
        :to="`/informations/${index + 1}`"
    >
      <span class="brush">
        <sprite id="brush-circle"></sprite>
      </span>
      <span class="title">{{ index + 1 }}</span>
    </router-link>
  </div>
</template>

<script>
import {isEmpty} from 'shared/utils/utils'
import {dynamicRoutes} from 'information/config/routes'
import Sprite from 'shared/components/utils/Sprite'

export default {
  name: 'informations-header',
  components: {Sprite},
  computed: {

  },
  methods: {

  }
}
</script>
