<template>
	<div v-if="headerType" class="fake-header__container">
		<div class="fake-header-bottom"></div>
		<header ref="header" role="banner" class="header mobile-header">
			<div class="top-line">
				<sprite id="line-horizontal-large"></sprite>
			</div>

			<div class="container-fluid full-width">
				<div class="row between-xs">

					<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 header-menu-container">

						<div key="dashboard" v-if="headerType === 'dashboard'" class="menu">
							<div class="row flex-nowrap start-xs middle-xs w-full">
								<div class="col-xs-7 col-ms-7 col-md-7 col-lg-7">
									<div class="form">
										<div class="input-container">
											<div class="select-container">
												<select name="see-questions" v-model="currentSelectRoute"
														@change="goToRoute">
													<option value="/">Tableau de bord</option>
													<option value="/#enfants">Enfants</option>
													<option value="/#profil">Profil</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>


						<informations-header v-else-if="headerType === 'resultQuestion'"></informations-header>


						<div key="resultFinal" v-else-if="headerType === 'resultFinal'" class="menu">
							<div class="row flex-nowrap between-xs middle-xs w-full">
								<div class="col-xs-6 col-sm-5 col-md-5 col-lg-5">
									<div class="form">
										<div class="input-container">
											<div class="select-container">
												<select name="see-questions" v-model="currentSelectQuestion"
														@change="goToQuestion">
													<option
														v-for="(q, index) in $store.state.question.questions"
														:key="q.id"
														:value="`#question-${index + 1}`"
													>Question&nbsp;{{index + 1}}
													</option>
												</select>
											</div>
										</div>
									</div>
								</div>

								<div class="col-xs-6 col-ms-6 col-md-6 col-lg-6 end-xs">
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</header>
	</div>
</template>
<script>
	import {dynamicRoutes, routes} from 'information/config/routes'
	import {toggleClass, addClass, removeClass, isDescendant, isEmpty} from 'shared/utils/utils'
	import InlineSvg from 'shared/components/utils/InlineSvg'
	import Sprite from 'shared/components/utils/Sprite'
	import RoundBtn from 'shared/components/atoms/btns/RoundBtn'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import InformationsHeader from 'information/components/headers/InformationsHeader'
	import {EventBus, EVENTS} from 'shared/event-bus'

	const headerAnimationS = 0.4

	export default {
		name: 'mobile-header',
		components: {InformationsHeader, BrushBtn, RoundBtn, Sprite, InlineSvg},
		data: function () {
			return {
				currentSelectQuestion: '#question-1',
				currentSelectRoute: '/'
			}
		},
		created() {
			this.currentHashOnScreen = this.currentHashOnScreen.bind(this)
			EventBus.$on(EVENTS.CURRENT_HASH_ON_SCREEN, this.currentHashOnScreen)
		},
		beforeDestroy() {
			EventBus.$off(EVENTS.CURRENT_HASH_ON_SCREEN, this.currentHashOnScreen)
		},
		computed: {
			headerType() {
				switch (this.$route.name) {
					case 'dashboard':
						return 'dashboard'
					case 'strategy':
						return 'dashboard'
					case 'questions':
						return 'resultQuestion'
					case 'result':
						return 'resultFinal'
					default:
						return null
				}
			},
		},
		methods: {
			currentHashOnScreen(hash) {
				if (this.headerType === 'resultFinal') {
					this.currentSelectQuestion = hash
				}

				if (this.headerType === 'dashboard') {
					this.currentSelectRoute = '/' + hash
				}
			},
			goToRoute(event) {
				this.$router.push(event.target.value)
			},
			goToQuestion(event) {
				this.$router.push(event.target.value)
			},
		}
	}
</script>
