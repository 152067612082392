//Error handling
const ERROR = 'ERROR'
const USER_ERROR = 'USER_ERROR'
const FETCH_INITIAL_DATA = 'FETCH_INITIAL_DATA'
const GET_DATA = 'GET_DATA'
const ROUTE_CHANGED = 'ROUTE_CHANGED'

const RESET_DATA = 'RESET_DATA'

//Loader
const START_LOADER = 'START_LOADER'
const STOP_LOADER = 'STOP_LOADER'
const STOP_INITIAL_LOADER = 'STOP_INITIAL_LOADER'
const STOP_ALL_LOADER = 'STOP_ALL_LOADER'







export default {
	STOP_ALL_LOADER,
	START_LOADER,
	STOP_LOADER,
	STOP_INITIAL_LOADER,
	RESET_DATA,
	ERROR,
	GET_DATA,
	ROUTE_CHANGED,
	USER_ERROR,
	FETCH_INITIAL_DATA
}
