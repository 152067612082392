import Vue from 'vue'
import Vuex from 'vuex'
import general from './modules/general'
import data from './modules/data'

Vue.use(Vuex)


//Store is where all the actions, and data are store (save)
const store = new Vuex.Store({
    modules: {
        general,
        data,
    },
    strict: process.env.NODE_ENV !== 'production'
})


export default store
