<template>
	<div class="app">
		<vue-header />
		<div class="vue-router-container">
			<div class="vue-router-inner-container">
				<transition
					name="fade"
					mode="out-in"
				>
					<router-view />
				</transition>
			</div>
		</div>

		<mobile-header />

	</div>
</template>
<script>
	import VueHeader from 'information/components/headers/VueHeader'
	import MobileHeader from 'information/components/headers/MobileHeader'
	export default {
		name:'information-app',
		components: {MobileHeader, VueHeader},
		metaInfo() {
			return {
				title: 'Tableau de bord',
				titleTemplate: `%s – éclo`
			}
		}
	}
</script>
