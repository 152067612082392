<template>
  <div class="mb-small bilan-question">
    <div class="question-answers" :class="{'no-next-answer': !nextAnswer }">

      <div class="bilan-question__arrow | row start-xs" v-if="nextAnswer">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <div class="flex flex-row center-xs">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 no-gutter relative text-yellow">
              <inline-svg id="arrow-step"></inline-svg>
            </div>
          </div>
        </div>
      </div>

      <div class="bilan-question__steps">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
          <!-- if a next answer -->
          <div v-if="nextAnswer">
            <div class="flex flex-row flex-wrap">
              <div class="mt-large xs:mt-x-large col-xs-12 col-sm-6 col-md-6 col-lg-6 no-gutter relative">
                <tooltip text="Étape actuelle" color="yellow"></tooltip>
                <bg-brush color="light-yellow" class="h-full">
                  <div class="step step-current relative">
                    <div v-if="answer">
                      <p class="text-align-left">{{ answer.comment }}</p>
                    </div>
                  </div>
                </bg-brush>
              </div>
              <div class="mt-large xs:mt-x-large col-xs-12 col-sm-6 col-md-6 col-lg-6 no-gutter relative">
                <tooltip text="Prochaine étape" color="blue"></tooltip>
                <bg-brush color="light-blue" class="h-full">
                  <div class="step step-next relative">
                    <div v-if="nextAnswer">
                      <p class="text-align-left">{{ nextAnswer.comment }}</p>
                    </div>
                  </div>
                </bg-brush>
              </div>
            </div>
          </div>
          <!-- if not a next answer -->
          <div v-else>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-gutter relative">
              <tooltip text="Étape actuelle" color="yellow"></tooltip>
              <bg-brush color="light-yellow" class="h-full">
                <div class="step step-current step-big relative">
                  <div v-if="answer" class="col-xs-8 no-gutter mx-auto">
                    <p class="mt-small text-align-left">{{ answer.comment }}</p>
                  </div>
                </div>
              </bg-brush>
            </div>
          </div>
        </div>
        <div class="bilan-question__strategy mt-x-large | col-xs-12 col-sm-12 col-md-12 col-lg-12 relative">
          <tooltip text="Stratégie à mettre en place" color="green"></tooltip>
          <div v-if="answer">
            <div class="h-full">
              <div class="flex flex-row flex-nowrap center-xs middle-xs h-full">
                <div class="">
                  <img class="block bilan-question__strategy__img mx-auto"
                       src="~shared/assets/images/strategie.png"/>
                </div>
                <div class="xs:mt-small">
                  <div class="copy mx-small">
                    <p class="text-align-left">{{ answer.strategyDescription }}</p>
                  </div>
                </div>
              </div>

              <div
			  	class="information__video xs:mt-medium mt-small"
			  	:style="videoBgImage ? `background-image: url(${videoBgImage})` : null"
			  >
                <p v-if="answer.video">
                  <span class="btn btn-default-brush" @click="openVideoModal">
                    <brush-btn color="white" type="btn-large">
                      Consulter la vidéo
                    </brush-btn>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showVideoModal">
      <modal type="large" v-on:close="closeVideoModal">
        <div class="embed-container">
          <iframe :src="`https://www.youtube.com/embed/${answer.video}`" frameborder="0" allowfullscreen></iframe>
        </div>

      </modal>
    </div>
  </div>
</template>

<script>
import {EventBus, EVENTS} from 'shared/event-bus'
import InlineSvg from 'shared/components/utils/InlineSvg'
import Tooltip from 'shared/components/atoms/Tooltip'
import BgBrush from 'shared/components/atoms/BgBrush'
import RoundBtn from 'shared/components/atoms/btns/RoundBtn'
import Modal from 'shared/components/container/Modal'
import BrushBtn from 'shared/components/atoms/btns/BrushBtn'

export default {
  name: 'information-step',
  components: {RoundBtn, BgBrush, Tooltip, InlineSvg, Modal, BrushBtn},
  props: {
    answer: {
      type: Object,
      required: true
    },
    nextAnswer: {
      type: Object,
      required: false
    }
  },
  data: function () {
    return {
      showVideoModal: false
    }
  },
  computed:{
    videoBgImage() {
		return this.$store.state.data.globals.videoBgImage || null
	},
    strategy(){
      return this.$store.state.data.strategies.find(s => s.id === this.answer.strategies[0].strategyId)
    }
  },
  methods: {
    openVideoModal() {
      this.showVideoModal = true
    },
    closeVideoModal() {
      this.showVideoModal = false
    },
  }
}
</script>

<style>
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
