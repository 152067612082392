//simple mapping of url

export const routes = {
    index: '/',
    information: '/informations/:orderIdx',
    error: '/404'
}

export const dynamicRoutes = {
    information: (orderIdx) => `/informations/${orderIdx}`,
}

