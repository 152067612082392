<template>
  <header role="banner" class="header desktop-header">
    <div class="container-fluid full-width">
      <div class="row between-xs no-gutter">
        <div class="header__logo | col-xs-6 col-sm-3 col-md-3 col-lg-3">
          <router-link to="/" class="h-normal inline-block logo-link">
            <inline-svg id="eclo-logo"></inline-svg>
          </router-link>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 mobile-hide header-menu-container">
          <informations-header v-if="headerType === 'information'"></informations-header>
        </div>


        <div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 end-xs">
          <div class="inline-block">

            <div
                class="flex flex-row middle-xs end-xs hamburger-menu-container"
                @click.prevent="toggleSidebarMenu"
                :class="{'open': sidebarMenuOpen}"
            >
              <h4 v-if="sidebarMenuOpen" class="menu-item mr-x-small">Fermer</h4>
              <h4 v-else class="menu-item mr-x-small">Menu</h4>


              <round-btn size="normal">
                <div class="hamburger-menu">
                  <span>
                    <sprite id="line-horizontal-xsmall"></sprite>
                  </span>
                  <span>
                    <sprite id="line-horizontal-xsmall"></sprite>
                  </span>
                  <span>
                    <sprite id="line-horizontal-xsmall"></sprite>
                  </span>
                </div>
              </round-btn>

            </div>
          </div>

        </div>
      </div>
    </div>


    <sidebar-header
        v-on:close="sidebarMenuOpen = false"
        v-on:open="sidebarMenuOpen = true"
    ></sidebar-header>

  </header>
</template>
<script>
import InlineSvg from 'shared/components/utils/InlineSvg'
import Sprite from 'shared/components/utils/Sprite'
import RoundBtn from 'shared/components/atoms/btns/RoundBtn'
import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
import {EventBus, EVENTS} from 'shared/event-bus'
import InformationsHeader from 'information/components/headers/InformationsHeader'
import SidebarHeader from 'shared/components/headers/SidebarHeader'


export default {
  name: 'vue-header',
  components: {SidebarHeader, InformationsHeader, BrushBtn, RoundBtn, Sprite, InlineSvg},
  data: function () {
    return {
      sidebarMenuOpen: false
    }
  },
  computed: {
    headerType() {
      switch (this.$route.name) {
        case 'information':
          return 'information'
        default:
          return 'default'
      }
    },
  },
  methods: {
    toggleSidebarMenu() {
      EventBus.$emit(EVENTS.TOGGLE_SIDEBAR_MENU)
    }
  }
}
</script>
