import {routes} from 'information/config/routes'

import CreateResult from 'information/components/pages/CreateResult'
import ErrorPage from 'shared/components/pages/ErrorPage'
import Information from 'information/components/pages/Information'

const vueRoutes = [
    {
        name: 'index',
        path: routes.index,
        component: CreateResult,
    },
    {
        name: 'information',
        path: routes.information,
        component: Information,
    },
      {
        name: 'create-result',
        path: '/bilans/nouveau',//old path
        redirect: routes.index
    },
    {
        name: '*',
        path: '*',
        redirect: routes.error
    },
    {
        name: '404',
        path: routes.error,
        component: ErrorPage
    }
]

export default vueRoutes
