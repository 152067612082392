<template>
  <div class="dropdown" :class="{'open': isOpen}">
    <div @click.prevent="toggle" class="dropdown__header">
      <slot name="header"></slot>
      <img src="~shared/assets/images/icons/dropdown-arrow.png" class="dropdown__header__arrow" />
    </div>
    <div ref="content" class="dropdown__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {TweenLite} from 'gsap/all'

export default {
  name: 'dropdown',
  data() {
    return {
      isOpen: false
    }
  },
  mounted() {
    TweenLite.set(this.$refs.content, {height: 0})
  },
  methods: {
    toggle() {
      if (!this.isOpen) {
        TweenLite.set(this.$refs.content, {height: "auto"})
        TweenLite.from(this.$refs.content, 0.35, {height: 0})
        this.isOpen = true
      } else {
        TweenLite.fromTo(this.$refs.content, 0.35, {height: 'auto'}, {height: 0})
        this.isOpen = false
      }
    }
  }
}
</script>